import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import { getUserFromLocalStorage } from '../../utils/dataManager';
import getUserList from '../../utils/getUserList';
import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";
import Navbar from '../Navbar';
import css from './ShowAllTicket.module.scss';
import { Alert, Loading } from "../UI";
import { ADD_TICKET } from "../../constants/routes";


const messages = {
  en: {
    title: 'New Order',
    edit: 'Edit',
    rebuy: 'Rebuy',
    from: 'From',
    find: 'Find',
    account: 'Account',
    cancel: 'Cancel',
    noData: 'No data',
    back: 'Back',
    buy: 'Buy',
  },
  cn: {
    title: '新单',
    edit: '更改',
    rebuy: '重买',
    from: '从',
    find: '寻找',
    account: '户口',
    cancel: '删除',
    noData: '没有数据',
    back: '反回',
    buy: 'Buy',
  }
}

class ShowAllTicket extends Component {
  static propTypes = {
    prop: PropTypes
  }

  state = {
    loading: false,
    user: '',
    date: new Date(),
    betList: [],
    betSelect: null,
  }

  async componentDidMount() {
    const { playerList } = this.props.userInfo || {};
    const users = getUserList(playerList || "");
    const id = getUserFromLocalStorage();
    if (id || users[0]) {
      this.setState({ user: id || users[0] }, () => {
        this.getBetHistory();
      });
    }
  }

  changeDate = (date) => {
    this.setState({ date })
  }

  getBetHistory = async () => {
    if (this.state.user) {
      const { password, user: username, pin } = this.props.userInfo || {};
      this.setState({ loading: true });
      const newState = { ...this.state, loading: false };
      const drawDate = `${moment(this.state.date).format('YYYY-MM-DD')}`;
      try {
        const response = await fetch(
          `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/findIP2.jsp?id=${this.state.user}&pass=${password}&user=${username}&dateType=0&number=&drawDate=${drawDate}&pageNo=&lang=1&currency=`,
          {
            method: "GET",
          }
        );
        const data = await response.text();
        const [success, message, result] = (data || '').split(';');
        if (success == 1) {
          newState.betList = (result || '').split('@');
        } else {
          newState.betList = [];
          console.log(message);
          // this.alert.open(message, true);
        }
      } catch (e) {
        console.log(e);
      }
      this.setState(newState);
    } else {
      this.alert.open('Please select a user!', true);
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  selectBet = (betIndex) => {
    this.setState({ betSelect: betIndex })
  }

  reBuyTicket = async () => {
    const { user, betList } = this.state;
    const { sessionId, password, currency, user: username, pin } =
      this.props.userInfo || {};
    const betInfo = betList[this.state.betSelect];
    if (betInfo) {
      const [, , betData] = betInfo.split(',');
      const [, , bet] = betData.split('\n');
      this.setState({ loading: true });
      try {
        const query = `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/orderIP.jsp?bet=D,-1,${bet.replace('#', '-')}&id=${user}&lang=1&user=${username}&remarks=&SESSIONID=${sessionId}&currency=${currency}&pass=${password}&buyFormat=D`;
        const response = await fetch(query, {
          method: "GET",
        });
        const data = await response.text();
        const [success, message, result] = (data || '').split(';');
        if (success == 1) {
          this.alert.open(result, false);
          this.orderId = message;
        } else {
          this.alert.open(message, true);
        }
      } catch (e) {
        this.alert.open(JSON.stringify(e), true);
      }
      this.setState({ loading: false });
    }
  }

  voidTicket = async () => {
    const { user, betList, betSelect } = this.state;
    const { password, user: username, pin } = this.props.userInfo || {};
    const betInfo = betList[betSelect] || '';
    if (betInfo) {
      const [orderId] = betInfo.split(',');
      if (orderId) {
        try {
          const query = `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/voidResitIP.jsp?&id=${user}&orderId=${orderId}&user=${username}&pass=${password}
        &lang=0`;
          this.setState({ loading: true })
          const response = await fetch(query, {
            method: "GET",
          });
          const data = await response.text();
          const [success, message, result] = (data || '').split(';');
          if (success == 1) {
            this.getBetHistory();
            this.alert.open(result);
          } else {
            this.alert.open(message, true);
          }
        } catch (e) {
          this.alert.open(JSON.stringify(e), true);
        }
        this.setState({ loading: false })
      } else {
        this.alert.open('Missing order id', true);
      }
    }
  }

  editTicket = () => {
    const { betList, betSelect } = this.state;
    const betInfo = betList[betSelect] || '';
    if (betInfo) {
      const [, , betDetail] = betInfo.split(',');
      if (betDetail) {
        localStorage.setItem('betDetail', betDetail);
        this.props.history.push(`${ADD_TICKET}?edit=true`);
      } else {
        this.alert.open('Cannot edit ticket', true);
      }
    }
  }

  render() {
    const { loading, user, betList, date, betSelect } = this.state;
    const { messages } = this.props;
    return (
      <div className={css.BrowseTicket}>
        <Alert
          ref={instance => (this.alert = instance)}
          onVoidTicket={this.voidTicket}
        />
        {loading && <Loading />}
        <Navbar
          leftItems={[
            {
              label: messages.back,
              onClick: this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={messages.title}
          rightItems={[
            {
              label: messages.rebuy,
              onClick: this.editTicket
            },
            {
              label: messages.buy,
              onClick: this.reBuyTicket,
            }
          ]}
        />
        <div className={css.FilterBox}>
          <label>
            {messages.account}: {user}
          </label>
          <div className={css.Row}>
            <label>
              {messages.from}
            </label>
            <DatePicker
              selected={date}
              onChange={this.changeDate}
              dateFormat="yyyy/MM/dd"
            />
            <button
              onClick={this.getBetHistory}
              type="button"
              className={`btn btn-primary ${css.FindBtn}`}
            >
              {messages.find}
            </button>
          </div>
        </div>
        <div className={css.Content}>
          {betList.length ? (
            <React.Fragment>
              {betList.map((bet, index) => {
                const isCancel = (bet || '').toLowerCase().includes('cancel');
                const style = {};
                if (isCancel) {
                  style.background = '#FECCFF';
                  style.color = '#FE0000';
                }
                if (index === betSelect) {
                  style.background = 'antiquewhite';
                }
                const [, data = ''] = (bet || '').split(',');
                return (
                  <div
                    key={index}
                    onClick={() => this.selectBet(index)}
                    style={style}
                  >
                    {data}
                  </div>
                )
              })}
            </React.Fragment>
          ) : (<b className="m-2">{messages.noData}</b>)}

        </div>
        <div className={`fixed-bottom ${css.Footer}`}>
          <div className={css.CtaWrapper}>
            <button
              type="button"
              className={`btn btn-light ${css.CtaButton}`}
              onClick={this.goBack}
            >
              {messages.cancel}
            </button>
            {betSelect !== null ? (
              <button
                type="button"
                className={`btn btn-light ${css.CtaButton}`}
                onClick={this.voidTicket}
              >
                Void
            </button>
            ) : ''}
            <button
              type="button"
              className={`btn btn-light ${css.CtaButton}`}
              onClick={this.reBuyTicket}
            >
              {messages.buy}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withLanguage(messages)(withAuth(ShowAllTicket))
