import React, {Component} from "react";
import PropTypes from "prop-types";
import Iwechat from '../../images/wechat.png';
import Ishare from "../../images/share.png";
import {WhatsappIcon, WhatsappShareButton} from "react-share";

// import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'


class Share extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // if( navigator.userAgent.match(/Android/i)
        //     || navigator.userAgent.match(/webOS/i)
        //     || navigator.userAgent.match(/iPhone/i)
        //     || navigator.userAgent.match(/iPad/i)
        //     || navigator.userAgent.match(/iPod/i)
        //     || navigator.userAgent.match(/BlackBerry/i)
        //     || navigator.userAgent.match(/Windows Phone/i)
        // ){
        //     // alert(1);
        // }
        // else {
        //     // alert(2);
        // }

    }

    clickshare = (message) => {
        if (navigator.share) {
            navigator.share({
                title: 'Web Fundamentals',
                text: message,
                // url: '',
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        }
    }
    clickWechat = (message) => {

        navigator.clipboard.writeText(message).then(function () {
            window.open(
                'weixin://',
                '_blank' // <- This is what makes it open in a new window.
            );
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    render() {
        return (
<div>
                {navigator.share ? <div style={css.content}><div style={css.content1}>
                    <img onClick={() => this.clickshare(this.props.message)}
                         style={css.btn} src={Ishare} alt="share"/>
                        </div></div> :
                    <div>
                        <div style={css.content}><div style={css.content1}>
                            <img onClick={() => this.clickWechat(this.props.message)}
                                 style={css.btn} src={Iwechat} alt="wechat"/>
                        </div></div>
                        <WhatsappShareButton
                            style={{float: 'left'}}
                            children="abc"
                            url={this.props.message}
                            size={100}
                            round={true}
                            beforeOnClick={this.close}
                            borderRadius={5}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        {/*<div style={css.content}><div style={{...css.content1,*/}
                            {/*background: '#2dc101',*/}
                            {/*'borderRadius': 30,*/}
                            {/*lineHeight: '30px',*/}
                            {/*color: 'white'}}>*/}
                            {/*<FileCopyOutlined onClick={() => copy2Clipboard(this.props.message)} />*/}
                        {/*</div></div>*/}

                    </div>

                }


            </div>
        )
    }
}

Share.propTypes = {};
const css = {
    content: {
        width: '38px',
        float: 'left',
        margin: '0'
    },
    content1: {
        float: 'left',
        width: '32px', height: '32px',
        margin: '2px 3px'
    },
    btn: {
        width: '100%', height: '100%', borderRadius: '30px',

    }
}
export default Share;


