
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import css from './Navbar.module.scss';
import './style.css';

export default class Navbar extends Component {
  static propTypes = {
    leftItems: PropTypes.array,
    title: PropTypes.string.isRequired,
    rightItems: PropTypes.array,
  }


  render() {
    const { leftItems, title, rightItems } = this.props;

    const creditPage = this.props.creditPage
    const activeCurencyBtn = this.props.activeCurencyBtn
    const activeCurency1 = this.props.activeCurency1
    const activeCurency2 = this.props.activeCurency2
      if(creditPage) {
          return (
              <nav className={`navbar navbar-light bg-light ${css.Navbar}`}>
                  <div className={`d-flex ${css.LeftItems}`}>
                      {(leftItems || []).map((item, index) => (
                          <button
                              className="btn"
                              onClick={item.onClick ? item.onClick : () => {}}
                              key={index}
                          >
                              {item.icon && (
                                  <i
                                      className={`fa ${item.icon}`}
                                      style={{ marginRight: "5px" }}
                                  />
                              )}
                              <label className="m-0" style={{ color: "blue" }}>
                                  {item.label}
                              </label>
                          </button>
                      ))}
                  </div>
                  <div style={{border: '1px solid #068df7', borderRadius: '5px'}}>
                      <button id={`curency_btn1`} className={activeCurency1}
                              onClick={() => activeCurencyBtn.onclick(1)}
                              style={{width: '100px'}}>MYR</button>
                      <button id={`curency_btn2`} className={activeCurency2}
                              onClick={() => activeCurencyBtn.onclick(2)}
                              style={{width: '100px'}}>SGD</button>
                  </div>
              </nav>
          )
      }
      else {
          return (
              <nav className={`navbar navbar-light bg-light ${css.Navbar}`}>
                  <div className={`d-flex ${css.LeftItems}`}>
                      {(leftItems || []).map((item, index) => (
                          <button
                              className="btn"
                              onClick={item.onClick ? item.onClick : () => {}}
                              key={index}
                          >
                              {item.icon && (
                                  <i
                                      className={`fa ${item.icon}`}
                                      style={{ marginRight: "5px" }}
                                  />
                              )}
                              <label className="m-0" style={{ color: "blue" }}>
                                  {item.label}
                              </label>
                          </button>
                      ))}
                  </div>

                  <span className={css.Title}>{title || ''}</span>
                  <div className={`d-flex ${css.RightItems}`}>
                      {(rightItems || []).map((item, index) => (
                          <button
                              className="btn"
                              onClick={item.onClick ? item.onClick : () => {}}
                              key={index}
                          >
                              {item.icon && (
                                  <i
                                      className={`fa ${item.icon}`}
                                      style={{ marginRight: "5px" }}
                                  />
                              )}
                              <label className="m-0" style={{ color: "blue" }}>
                                  {item.label}
                              </label>
                          </button>
                      ))}
                  </div>
              </nav>
          )
      }

  }
}
