import React, { Component } from 'react'
import PropTypes from 'prop-types'

import css from './RedNumber.module.scss';
import { Alert, Loading } from "../UI";
import Navbar from '../Navbar';
import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";

const messages = {
  en: {
    title: 'Red number',
    print: 'Print',
    back: 'Back',
  },
  cn: {
    title: '红字',
    print: '打印',
    back: '反回',
  }
}

class RedNumber extends Component {
  state = {
    loading: false,
    result: '',
  }

  componentDidMount() {
    this.getRedNumber();
  }


  getRedNumber = async () => {
    const { password, user: username, pin } = this.props.userInfo || {};
    this.setState({ loading: true });
    const newState = { ...this.state, loading: false };

    try {
      const response = await fetch(
        `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/hotnumberIP.jsp?id=${username}&pass=${password}`,
        {
          method: "GET",
        }
      );
      const data = await response.text();
      const [success, result] = (data || '').split(';');
      if (success == 1) {
        newState.result = result;
        newState.showResult = true;
      } else {
        newState.result = '';
      }
    } catch (e) {
      console.log(e);
    }
    this.setState(newState);
  }

  goBack = () => {
    this.props.history.goBack();
  };

  print = () => {
    window.print();
  }

  render() {
    const { loading, result } = this.state;
    const { messages } = this.props;
    return (
      <div className={css.RedNumber}>
        <Alert
          ref={instance => (this.alert = instance)}
          onVoidTicket={this.voidTicket}
        />
        {loading && <Loading />}
        <Navbar
          leftItems={[
            {
              label: messages.back,
              onClick: this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={messages.title}
          rightItems={[{
            label: messages.print,
            onClick: this.print,
          }]}
        />
        <div id="result" className={css.Result}>
          {result}
        </div>
      </div>
    )
  }
}

export default withLanguage(messages)(withAuth(RedNumber))
