const getUserList = (playerList) => {
  const players = playerList.split(",");
  const users = players.map(player => {
    const [username] = player.split("@");
    return username;
  });
  return users;
}

export default getUserList;
