import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import css from './Alert.module.scss';
import Share from './share';
import './Alert.module.scss';
import copy from 'copy-to-clipboard'

const initialState = {
  open: false,
  openConfirm: false,
  message: "",
  isError: false,
  showVoidAndShareBtn: false,
};

class Alert extends PureComponent {
  copy2Clipboard = (text) => {
    copy(text)
    // alert('Has been copied: \n' + text)
  }
  state = initialState;

  open = (message, isError = false, showVoidAndShareBtn) => {
    this.setState({ open: true, message, isError, showVoidAndShareBtn });
  };

  close = () => {
    this.setState(initialState);
    if (this.props.onClearScreen) {
      this.props.onClearScreen();
    }
  };
  closeConfirm = () => {
    this.setState({
      openConfirm: false
    })
  }
  confirm = () => {
    this.setState({
      openConfirm: true
    })
  }
  onVoidTicket = () => {
    if (this.props.onVoidTicket) {
      this.props.onVoidTicket();
    }
    this.close();
  }

  render() {
    const { message, open, isError, showVoidAndShareBtn, openConfirm } = this.state;
    const isCancel = (message || '').toLowerCase().includes('cancel');
    const bodyStyle = {};
    const contentStyle = {};
    if (isCancel) {
      contentStyle.background = '#FECCFF';
      contentStyle.color = '#FE0000';
    }
    if (isError) {
      // bodyStyle.textAlign = 'left';
      // bodyStyle.marginLeft = '20px';
    }
    return (
      <div
        className={`modal ${css.Alert}`}
        tabIndex="-1"
        role="dialog"
        // style={{ display: 'block' }}
        style={{ display: `${open ? 'block' : 'none'}`, marginTop: `calc((100vh - ${isError ? '180px' : '332px'}) / 2)` }}
      >
        <div
            className={`modal ${css.Alert}`}
            tabIndex="-1"
            role="dialog"
            // style={{ display: 'block' }}
            style={{ display: `${openConfirm ? 'block' : 'none'}`, marginTop: `calc((100vh - ${isError ? '180px' : '332px'}) / 2)` }}
        >
          <div className="modal-dialog" role="document">
            <div className={`modal-content ${css.Content}`} style={contentStyle}>
              <div className={`modal-header ${css.Header}`}>
                <i className="fa fas fa-exclamation-triangle" aria-hidden="true" style={{ fontSize: '35px', color: '#f9bc07' }}></i>
              </div>
              <div className={`modal-body ${css.Body}`} style={bodyStyle}>
                Are you sure ?
              </div>
              <div className={`modal-footer ${css.Footer}`}>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={this.closeConfirm}
                >
                  NO
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={this.onVoidTicket}
                >
                  YES
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-dialog" role="document">
          <div className={`modal-content ${css.Content}`} style={contentStyle}>
            <div className={`modal-header ${css.Header}`}>
              {isError ? (
                <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ fontSize: '35px', color: '#ffa900' }}></i>
              ) : (
                  <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: '35px', color: 'green' }}></i>
                )}
            </div>
            <div className={`modal-body ${css.Body}`} style={bodyStyle}>{message}</div>
            <div className={`modal-footer ${css.Footer}`}>

              {open ? <Share message = {message}/> : ''}
              {/*<Share message = {message}/>*/}
              {showVoidAndShareBtn && (
                <React.Fragment>

                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.copy2Clipboard(message)}
                  >
                    COPY
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={this.confirm}
                  >
                    VOID
                  </button>

                </React.Fragment>
              )}

              <button
                type="button"
                className="btn btn-light"
                onClick={this.close}
              >
                PRINT
                  </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={this.close}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Alert.propTypes = {
  onVoidTicket: PropTypes.func
};

export default Alert;
