import React from "react";
import css from "./Loading.module.scss";

const Loading = ({}) => (
  <div className={`spinner-border text-primary ${css.Loading}`} role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

export default Loading;
