const USER_KEY = 'user';

const setUserToLocalStorage = (user) => {
  return localStorage.setItem(USER_KEY, user);
}

const getUserFromLocalStorage = () => {
  return localStorage.getItem(USER_KEY) || '';
}

export { setUserToLocalStorage, getUserFromLocalStorage };
