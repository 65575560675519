import React from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthConsumer } from "./AuthContext";
import * as ROUTES from "../../constants/routes";

const ProtectedRoute = ({ component: Component, role, ...rest }) => (
  <AuthConsumer>
    {({ authenticated }) => (
      <Route
        render={props =>
          authenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={ROUTES.SIGN_IN} />
          )
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);

export default ProtectedRoute;
