export const SIGN_IN = "/";
export const DASHBOARD = "/dashboard";
export const ADD_TICKET = "/add-ticket";
export const TICKET_MANAGEMENT = "/ticket-management";
export const TICKET_MANAGEMENT_BROWSE_TICKET = "/ticket-management/browse-ticket";
export const TICKET_MANAGEMENT_SHOW_ALL_TICKET = "/ticket-management/all-ticket";
export const TICKET_MANAGEMENT_FIND_BY_BET_NUMBER = "/ticket-management/find";
export const SETTINGS = "/settings";
export const DRAW_RESULT = "/draw-result";
export const REPORT = "/report";
export const RED_NUMBER = "/red-number";
