import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import { getUserFromLocalStorage } from '../../utils/dataManager';
import getUserList from '../../utils/getUserList';
import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";
import Navbar from '../Navbar';
import css from './Report.module.scss';
import { Alert, Loading } from "../UI";

const messages = {
  en: {
    title: 'Report',
    print: 'Print',
    show: 'Show',
    back: 'Back',
    serverReply: 'Server Reply',
    from: 'From',
    until: 'Until'
  },
  cn: {
    title: '报告',
    print: '打印',
    show: '显示',
    back: '反回',
    serverReply: 'Server Reply',
    from: '从',
    until: '到'
  }
}

class Report extends Component {
  static propTypes = {
    prop: PropTypes
  }

  state = {
    loading: false,
    user: '',
    users: [],
    from: new Date(),
    until: new Date(),
    result: '',
    showResult: false,
  }

  async componentDidMount() {
    const { playerList } = this.props.userInfo || {};
    const users = getUserList(playerList || "");
    const id = getUserFromLocalStorage();
    if (id || users[0]) {
      this.setState({ users, user: id || users[0] });
    }
  }

  changeDate = (prop, date) => {
    this.setState({ [prop]: date })
  }

  getBetHistory = async () => {
    if (this.state.user) {
      const { password, user: username, pin } = this.props.userInfo || {};
      this.setState({ loading: true });
      const newState = { ...this.state, loading: false };
      const startDate = `${moment(this.state.from).format('YYYYMMDD')}`
      const endDate = `${moment(this.state.until).format('YYYYMMDD')}`
      try {
        const response = await fetch(
          `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/reportIP.jsp?user=${username}&pass=${password}&startDate=${startDate}&endDate=${endDate}&currency=MYR`,
          {
            method: "GET",
          }
        );
        const data = await response.text();
        const [success, message, result] = (data || '').split(';');
        if (success == 1) {
          newState.result = result;
          newState.showResult = true;
        } else {
          newState.result = '';
          console.log(message);
          this.alert.open(message, true);
        }
      } catch (e) {
        console.log(e);
      }
      this.setState(newState);
    } else {
      this.alert.open('Please select a user!', true);
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  changeUser = event => {
    this.setState({ user: event.target.value });
  };

  print = () => {
    window.print();
  }

  render() {
    const { loading, user, users, from, until, showResult, result } = this.state;
    console.log(showResult)
    const { messages } = this.props;
    return (
      <div className={css.BrowseTicket}>
        <Alert
          ref={instance => (this.alert = instance)}
          onVoidTicket={this.voidTicket}
        />
        {loading && <Loading />}
        <Navbar
          leftItems={[
            {
              label: messages.back,
              onClick: showResult ? () => this.setState({ showResult: false }) : this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={showResult ? messages.serverReply : messages.title}
          rightItems={showResult ? [{
            label: messages.print,
            onClick: this.print,
          }] : []}
        />
        {showResult ? (<div id="result" className={css.Result}>
          {result}
        </div>) : (
            <React.Fragment>
              <div className={css.FilterBox}>
                <div className={`form-group ${css.ListUser}`}>
                  <select
                    className="form-control"
                    onChange={this.changeUser}
                    value={user}
                  >
                    {users.map((username, index) => (
                      <option key={index}>{username}</option>
                    ))}
                  </select>
                </div>
                <b>{messages.from}</b>
                <DatePicker
                  selected={from}
                  onChange={(date) => this.changeDate('from', date)}
                  dateFormat="yyyy/MM/dd"
                />
                <b>{messages.until}</b>
                <DatePicker
                  selected={until}
                  onChange={(date) => this.changeDate('until', date)}
                  dateFormat="yyyy/MM/dd"
                />
                <button
                  onClick={this.getBetHistory}
                  type="button"
                  className={`btn btn-light ${css.FindBtn}`}
                >
                  {messages.show}
                </button>
              </div>
            </React.Fragment>
          )}

      </div>
    )
  }
}

export default withLanguage(messages)(withAuth(Report))
