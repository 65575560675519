import React, { Component } from "react";
import PropTypes from "prop-types";
import scrollIntoView from 'scroll-into-view';
import sum from 'lodash/sum';
import isNaN from 'lodash/isNaN'
import * as perm  from 'array-permutation'

import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";
import ModalSelectDate from "./ModalSelectDate";
import { Alert, Loading } from "../UI";
import { genNextDays, getOrderFormatList, getUserList } from "../../utils";
import { NumberKeyboard, SpecialKeyboard } from '../../constants/keyboards';
import { TICKET_MANAGEMENT_BROWSE_TICKET } from '../../constants/routes';
import Navbar from '../Navbar';
import ImageDown from '../../images/button-down.png';
import ImageLeft from '../../images/button-left.png';
import ImageUp from '../../images/button-up.png';
import {NumberKeyboardMobile} from '../../constants/keyboards';
import css from "./AddTicket.module.scss";

const messages = {
  en: {
    back: 'Back',
    title: 'New Order',
    send: 'BET',
    line: 'Line',
    clearScreen: 'Clear screen',
    insertLine: 'Insert line',
    paste: 'Paste text',
    invalidInput: 'Invalid input',
    void: 'Void',
    share: 'Share',
    print: 'Print',
    ok: 'Ok'
  },
  cn: {
    back: '反回',
    title: '主题',
    send: '发出',
    line: '排',
    clearScreen: '澄清屏幕',
    insertLine: '插入',
    paste: '粘',
    invalidInput: '输入无效',
    void: '废票',
    share: '共享',
    print: '打印',
    ok: 'OK'
  }
}

const input = {
  value: "",
  disabled: false
};

const initialState = {
  lines: [
    {
      value: "D#",
      disabled: true
    }
  ],
  lineSelected: 1,
  dateSelection: [],
  bill: 0,
  openModalSelectDate: false,
  showAlert: false
};
let curency = localStorage.getItem('curency') || 'MYR'

class AddTicket extends Component {
  state = {
    ...initialState,
    orderFormat: "",
    user: "",
    users: [],
    orderFormats: [],
    activeCurency1: curency == 'MYR' ? 'active' : '',
    activeCurency2:  curency == 'MYR' ? '' : 'active',
    permCount: 1,
    mobile: '',
    date: 'D'
  };

  activeCurencyBtn = (type) => {
    if(type == 1 ){
      this.setState({
        activeCurency1:'active',
        activeCurency2: ''
      })
      curency = 'MYR'
      localStorage.setItem("curency", 'MYR');
    } else {
      this.setState({
        activeCurency2:'active',
        activeCurency1: ''
      })
      curency = 'SGD'
      localStorage.setItem("curency", 'SGD');
    }

  };
  componentDidMount() {
    const { playerList, orderFormat } = this.props.userInfo || {};
    const users = getUserList(playerList || "");
    const orderFormats = getOrderFormatList(orderFormat || "");
    this.setState({
      users,
      orderFormats,
      user: users[0],
      orderFormat: orderFormats[0]
    });
    if (window.location.search === '?edit=true') {
      const betDetail = localStorage.getItem('betDetail');
      const [, company, ...bets] = betDetail.split('\n');
      const lines = [
        {
          value: "D#",
          disabled: true
        },
        {
          value: company,
          disabled: false
        },
      ]
      const betLines = bets.map((bet) => {
        return {
          value: bet,
          disabled: false
        };
      })
      this.setState({ lines: [...lines, ...betLines] }, () => {
        this.getBill(lines)
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.lineSelected !== this.state.lineSelected &&
      this.allowScroll
    ) {
      this.scrollToLineSelected();
    }
  }

  insertLine = () => {
    this.setState({ lines: [...this.state.lines, input] }, () => {
      this.setState({ lineSelected: this.state.lines.length });
    });
  };

  scrollToLineSelected = () => {
    // const lineSlect = document.getElementById('line-selected');
    // if (lineSlect) lineSlect.scrollIntoView();
    scrollIntoView(document.getElementById("line-selected"))
  };

  clearScreen = () => {
    // window.location.reload();
    this.setState((prevState) => ({
      ...prevState,
      ...initialState,
    }));
  };

  onSelectKey = value => {
    let isBill = false
    if(value === 'BET') {
      this.saveTicket()
      // console.log(12324)
    } else if(value === 'delete') {
      this.onBackspace()
    } else if(value === 'enter') {
      this.insertLine()
    } else if(value === 'clear') {
      this.clearScreen()
    } else {
      const lines = this.state.lines.map((line, _index) => {
        // console.log(line, _index)
        let newValue = line.value;
        if (value === "BOX" || value === "IBOX") {
          const oneSubStr = line.value.substring(0, 1);
          const twoSubStr = line.value.substring(0, 2);
          if (twoSubStr === "**") {
            if (value === "BOX") {
              newValue = `*${line.value.slice(2)}`;
            }
          } else if (oneSubStr === "*") {
            if (value === "IBOX") {
              newValue = `**${line.value.slice(1)}`;
            }
          } else {
            newValue = `${value === "BOX" ? "*" : "**"}${line.value}`;
          }
        } else {

          const _newValue = `${line.value}${value}`;

          const countThang = _newValue.split('#').length
          if (countThang < 11) {
            if(_index > 1 && countThang >= 2) {
              // console.log(_newValue.split('#'))
              isBill = true
            }
            newValue = _newValue;
          }
        }

        if (_index === this.state.lineSelected - 1) {
          return {
            ...line,
            value: newValue
          };
        }
        return line;
      });
      this.setState({lines}, () => {
        // alert()
        // console.log(isBill)
        isBill && this.getBill(lines);
      });
    }
  };

  onBackspace = () => {
    const lines = this.state.lines.map((line, _index) => {
      if (_index === this.state.lineSelected - 1) {
        return {
          ...line,
          value: line.value.slice(0, -1)
        };
      }
      return line;
    });
    this.setState({ lines }, () => {
      this.getBill(lines);
    });
  };

  changeSelectDate = dateSelection => {
    this.setState({
      dateSelection,
      openModalSelectDate: !this.state.openModalSelectDate
    });
  };

  changeUser = event => {
    this.setState({ user: event.target.value });
  };

  changeOrderFormat = event => {
    this.setState({ orderFormat: event.target.value });
  };

  calculatorPemr = values => { // console.log(values)
    const iter = perm(values);
    let arr = []
    for (let p of iter) {
      let s = ''
      p.map(i => {
        s +=i
      })
      arr.push(s)
      arr= [...new Set(arr)];
      // console.log(permCount, arr, arr.length)
    }
    return arr.length > 0 ? arr.length : 1
    // this.setState({ permCount: arr.length > 0 ? arr.length : 1});
  };

  getBill = (lines) => {

    const [, companies, ...bet] = lines;
    if (companies && bet.length) {
      const { value: companyInput = "" } = companies;
      const totalCompany = companyInput.replace("#", "").length;

      let amounts = 0
      for (let index = 0; index < bet.length; index++) {
        let totalAmount = 0;
        const { value = "" } = bet[index] || {};

        const [betValues, ...amount] = value.split("#");
        if (amount) {


          const __amount = sum(amount.map((_amount) => isNaN(parseInt(_amount, 10)) ? 0 : parseInt(_amount, 10)))
          // console.log(totalAmount, index, __amount)
          if (__amount) {
            totalAmount += __amount;
          } else {

            totalAmount += amounts[amounts.length - 1] || 0;
          }

        } else {
          totalAmount += amounts[amounts.length - 1] || 0;
        }
        const countsao = (value.match(/\*/g) || []).length;
        if(countsao == 1) totalAmount *= this.calculatorPemr(betValues.replace('*', '').split(''))
        amounts += totalAmount
      }
      this.setState({ bill: totalCompany * amounts });
    }
  };

  saveTicket = async () => {
    const { user, lines, dateSelection } = this.state;
    console.log(lines)
    const [date, companies, ...bet] = lines;
    const { sessionId, password, currency, user: username, pin } =
      this.props.userInfo || {};
    if (companies && bet.length) {
      const { value: companyInput = "" } = companies;
      const _companies = companyInput.replace("#", "");
      let _bet = bet
        .filter(({ value }) => value)
        .map(({ value }) => value.replace(/#/g, '-'))
        .join(",");
      let _date = date.value.replace(/#/g, '-')
      // let date = "D";
      // if (dateSelection.length) {
      //   date = `D${dateSelection.join("")}`;
      // }
      // if (date === 'DAUTO') {
      //   date = "D";
      // }
      this.setState({ loading: true });
      try {
        const query = `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/orderIP.jsp?bet=${_date},-${_companies},${_bet}&id=${user}&lang=1&user=${username}&remarks=&SESSIONID=${sessionId}&currency=${curency}&pass=${password}&buyFormat=D&phone=${this.state.mobile}`;
        const response = await fetch(query, {
          method: "GET",
        });
        const data = await response.text();
        const [success, message, result] = (data || '').split(';');
        if (success == 1) {
          this.alert.open(result, false, true);
          this.orderId = message;
        } else {
          this.alert.open(message, true);
        }
      } catch (e) {
        this.alert.open(JSON.stringify(e), true);
      }
      this.setState({ loading: false });
    } else {
      this.alert.open("Invalid input", true);
    }
    this.amounts = [];
  };

  voidTicket = async () => {
    const { user } = this.state;
    const { password, user: username, pin } = this.props.userInfo || {};
    if (this.orderId) {
      try {
        const query = `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/voidResitIP.jsp?&id=${user}&orderId=${this.orderId}&user=${username}&pass=${password}
        &lang=0`;
        this.setState({ loading: true })
        const response = await fetch(query, {
          method: "GET",
        });
        const data = await response.text();
        const [success, message, result] = (data || '').split(';');
        if (success == 1) {
          this.alert.open(result);
        } else {
          this.alert.open(message, true);
        }
      } catch (e) {
        this.alert.open(JSON.stringify(e), true);
      }
      this.setState({ loading: false })
    } else {
      this.alert.open('Missing order id', true);
    }
    // this.props.history.push(TICKET_MANAGEMENT_BROWSE_TICKET)
  }

  goBack = () => {
    this.props.history.goBack();
  };

  selectLine = lineIndex => {
    this.allowScroll = false;
    const newState = { ...this.state, lineSelected: lineIndex + 1 };
    // if (lineIndex === 0) {
    //   newState.openModalSelectDate = true;
    // }
    this.setState(newState);
  };

  moveToPrevLine = () => {
    this.allowScroll = true;
    if (this.state.lineSelected === 1) {
      return;
    }
    this.setState({ lineSelected: this.state.lineSelected - 1 });
  };

  moveToNextLine = () => {
    this.allowScroll = true;
    if (this.state.lineSelected === this.state.lines.length) {
      this.insertLine();
    } else {
      this.setState({
        lineSelected: this.state.lineSelected + 1
      });
    }
  };
  updateInputValue = (evt) => {
    this.setState({
      mobile: evt.target.value
    });
  }
  updateDate = (evt) => {
    this.setState({
      date: evt.target.value
    });
  }
  closeKeyBoard = (evt) => {
    this.setState({
      lineSelected: 1
    });
  }
  render() {
    const {
      lines,
      lineSelected,
      users,
      orderFormats,
      openModalSelectDate,
      dateSelection,
      bill,
      user,
      orderFormat,
      loading
    } = this.state;
    const { messages } = this.props;
    return (
      <div className={css.AddTicket}>
        <Alert
          ref={instance => (this.alert = instance)}
          onVoidTicket={this.voidTicket}
          onClearScreen={this.clearScreen}
        />
        {loading && <Loading />}
        {openModalSelectDate && (
          <ModalSelectDate
            dateSelect={genNextDays(14)}
            onSelect={this.changeSelectDate}
            onClose={() => this.setState({ openModalSelectDate: false })}
          />
        )}
        <Navbar
          leftItems={[
            {
              label: messages.back,
              onClick: this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={messages.title}
          rightItems={[
            {
              label: messages.send,
              onClick: this.saveTicket
            }
          ]}
            creditPage={true}
          activeCurencyBtn = {{onclick: this.activeCurencyBtn}}
          activeCurency1 = {this.state.activeCurency1}
          activeCurency2 = {this.state.activeCurency2}
        />
        <div className="d-flex p-1">
          <div className={`${css.ListUserWrap}`}>
            <div className={`form-group ${css.ListUser}`}>
              <select
                  className="form-control"
                  onChange={this.changeUser}
                  value={user}
              >
                {users.map((username, index) => (
                    <option key={index}>{username}</option>
                ))}
              </select>
            </div> <span className={`${css.ListUserMobile}`}>SMS</span>
              <input type="tel" onChange={this.updateInputValue}
                     onFocus={this.closeKeyBoard}
                     className={`form-control ${css.ListUserMobile}`} style={{width: '40%', margin: '0 0 0 10px'}}/>
          </div>

        </div>
        <div
          id="ticket-content"
          className={css.TicketContent}
          style={{
            height:
              lineSelected === 1 ? "calc(100vh - 201px)" : "calc(100vh - 400px)"
          }}
        >
          {lines.map((line, index) => (
            <React.Fragment key={index}>
              <span>
                <button
                  id={lineSelected === index + 1 && "line-selected"}
                  onClick={() => this.selectLine(index)}
                  style={
                    lineSelected === index + 1 || index === 0
                      ? {
                        backgroundColor:
                          index === 0 ? "aliceblue" : "antiquewhite"
                      }
                      : {}
                  }
                  type="button"
                  className={`btn btn-default w-100 ${css.D}`}
                >
                  {line.value || ""}
                  {/*{index === 0*/}
                    {/*? dateSelection.length ? `D${dateSelection.join("")}` : 'D'*/}
                    {/*: line.value || ""}*/}
                </button>
              </span>
              <br />
            </React.Fragment>
          ))}
          <div
            style={{ height: '-webkit-fill-available' }}
            onClick={() => {
              this.allowScroll = true;
              this.insertLine()
            }} />
        </div>
        <div className={`fixed-bottom ${css.Footer}`}>
          <div className="d-flex justify-content-between">
            <b>${bill.toFixed(2)}</b>
            <b>
              {messages.line} {lineSelected}/{lines.length}
            </b>
          </div>

                {(
                // {this.state.lineSelected !== 1 && (
                    <React.Fragment>
                        <div className={`row ${css.NumberKeyboard}`}>
                            {NumberKeyboardMobile.map(({label, value, image}, index) => (
                                <div className="col-3" key={index}>
                                    <img src={image} onClick={() => this.onSelectKey(value)} alt="keyboard"/>
                                </div>
                            ))}
                        </div>

                    </React.Fragment>
                )}
        </div>
      </div>
    );
  }
}

AddTicket.propTypes = {
  userInfo: PropTypes.object,
  history: PropTypes.object
};

export default withLanguage(messages)(withAuth(AddTicket));
