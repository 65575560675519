import React from 'react';
import { withRouter } from "react-router-dom";

import { AuthConsumer } from "./AuthContext";

const withAuth = (Component) => {
  class WithAuth extends React.Component {
    render() {
      return (
        <AuthConsumer>
          {(props) =>
            <Component {...this.props} {...props} />
          }
        </AuthConsumer>
      );
    }
  }
  return (withRouter(WithAuth));
}

export default withAuth;