// import Image0 from '../images/button-00.png';
// import Image1 from '../images/button-1.png';
// import Image2 from '../images/button-2.png';
// import Image3 from '../images/button-3.png';
// import Image4 from '../images/button-4.png';
// import Image5 from '../images/button-5.png';
// import Image6 from '../images/button-6.png';
// import Image7 from '../images/button-7.png';
// import Image8 from '../images/button-8.png';
// import Image9 from '../images/button-9.png';

import ImageStar from '../images/button-star.png';
// import ImageBox from '../images/button-box.png';
// import ImageIBox from '../images/button-ibox.png';
import ImageHashtag from '../images/button-hashtag.png';

import Image0 from '../images/keyboard/0.png';
import Image1 from '../images/keyboard/1.png';
import Image2 from '../images/keyboard/2.png';
import Image3 from '../images/keyboard/3.png';
import Image4 from '../images/keyboard/4.png';
import Image5 from '../images/keyboard/5.png';
import Image6 from '../images/keyboard/6.png';
import Image7 from '../images/keyboard/7.png';
import Image8 from '../images/keyboard/8.png';
import Image9 from '../images/keyboard/9.png';

import ImageLeft from '../images/keyboard/left.png';
import ImageDelete from '../images/keyboard/delete.png';
import ImageRoll from '../images/keyboard/roll.png';
import ImageClear from '../images/keyboard/clear.png';
import ImageRight from '../images/keyboard/right.png';
import ImageAdd from '../images/keyboard/add.png';
import ImageEnter from '../images/keyboard/enter.png';
import ImageBet from '../images/keyboard/bet.png';
import ImageSao from '../images/keyboard/button01.jpg';
import ImageThang from '../images/keyboard/button02.jpg';
const NumberKeyboard = [
  {
    label: "1",
    value: "1",
    image: Image1,
  },
  {
    label: "2",
    value: "2",
    image: Image2,
  },
  {
    label: "3",
    value: "3",
    image: Image3,
  },
  {
    label: "4",
    value: "4",
    image: Image4,
  },
  {
    label: "5",
    value: "5",
    image: Image5,
  },
  {
    label: "6",
    value: "6",
    image: Image6,
  },
  {
    label: "7",
    value: "7",
    image: Image7,
  },
  {
    label: "8",
    value: "8",
    image: Image8,
  },
  {
    label: "9",
    value: "9",
    image: Image9,
  }
];

const SpecialKeyboard = [
  {
    label: "*",
    value: "*",
    image: ImageStar,
  },
  // {
  //   label: "BOX",
  //   value: "BOX",
  //   image: ImageBox,
  // },
  {
    label: "0",
    value: "0",
    image: Image0
  },
  // {
  //   label: "IBOX",
  //   value: "IBOX",
  //   image: ImageIBox,
  // },
  {
    label: "#",
    value: "#",
    image: ImageHashtag,
  }
];

const NumberKeyboardMobile = [
  {
    label: "1",
    value: "1",
    image: Image1,
  },
  {
    label: "2",
    value: "2",
    image: Image2,
  },
  {
    label: "3",
    value: "3",
    image: Image3,
  },
  {
    label: "3",
    value: "delete",
    image: ImageDelete,
  },
  {
    label: "4",
    value: "4",
    image: Image4,
  },
  {
    label: "5",
    value: "5",
    image: Image5,
  },
  {
    label: "6",
    value: "6",
    image: Image6,
  },
  {
    label: "6",
    value: "clear",
    image: ImageClear,
  },
  {
    label: "7",
    value: "7",
    image: Image7,
  },
  {
    label: "8",
    value: "8",
    image: Image8,
  },
  {
    label: "9",
    value: "9",
    image: Image9,
  },
  {
    label: "#",
    value: 'BET',
    image: ImageBet,
  },

  {
    label: "*",
    value: '*',
    image: ImageSao,
  },

  {
    label: "0",
    value: "0",
    image: Image0
  },

  {
    label: "#",
    value: '#',
    image: ImageThang
  },
  {
    label: "9",
    value: 'enter',
    image: ImageEnter,
  }
];
export { NumberKeyboard, SpecialKeyboard, NumberKeyboardMobile };
