import React, { Component } from "react";
import PropTypes from "prop-types";

const LanguageContext = React.createContext();

class LanguageProvider extends Component {

  state = {
    lang: 'cn',
    // lang: 'en',
  }

  componentWillMount() {
    const lang = localStorage.getItem("lang");
    if (lang) {
      this.setState({ lang });
    }
  }

  changeLanguage = (lang) => {
    this.setState({ lang }, () => {
      localStorage.setItem("lang", lang);
    })
  }

  render() {
    return (
      <LanguageContext.Provider
        value={{
          lang: this.state.lang,
          changeLanguage: this.changeLanguage,
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

LanguageProvider.propTypes = {
  children: PropTypes.node
};

const LanguageConsumer = LanguageContext.Consumer;

export { LanguageProvider, LanguageConsumer };
