import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { AuthConsumer } from "../App/AuthContext";
import * as ROUTES from "../../constants/routes";
import Navbar from '../Navbar';
import withLanguage from "../App/withLanguage";

import newTicketImage from "../../images/new-ticket.png";
import ticketManagerImage from "../../images/ticket-manager.png";
import reportImage from "../../images/report.png";
import drawResultImage from "../../images/draw-result.png";
import redNumberImage from "../../images/red-number.png";
import settingImage from "../../images/setting2.png";

const messages = {
  en: {
    update: 'Update',
    title: 'Main menu',
    exit: 'Exit',
    newTicket: 'New Order',
    ticketManagement: 'Check/Void Ticket',
    report: 'Report',
    drawResult: 'Draw result',
    redNumber: 'Red number',
    setting: 'Setting'
  },
  cn: {
    update: '更新',
    title: '主题',
    exit: '登出',
    newTicket: '新单',
    ticketManagement: '检查/废票-票单',
    report: '报告',
    drawResult: '成绩',
    redNumber: '红字',
    setting: '设置'
  }
}

class Dashboard extends Component {
  render() {
    const { messages } = this.props;
    return (
      <AuthConsumer>
        {({ logout }) => (
          <div style={{ backgroundColor: "aliceblue" }}>
            <Navbar
              leftItems={[
                {
                  label: messages.update,
                  onClick: () => { },
                }
              ]}
              title={messages.title}
              rightItems={[
                {
                  label: messages.exit,
                  onClick: logout
                }
              ]}
            />
            <div className="row m-0">
              <div className="col col-6 text-center m-0 p-0">
                <a
                  className="btn btn-sq-lg p-0"
                  onClick={() => this.props.history.push(ROUTES.ADD_TICKET)}
                  style={{
                    width: "100%",
                    height: "calc((100vh - 46px) / 3)",
                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd"
                  }}
                >
                  <img src={newTicketImage} style={{ width: "100px" }} />
                  <br />
                  {messages.newTicket}
                </a>
              </div>
              <div className="col col-6 text-center m-0 p-0">
                <a
                  className="btn btn-sq-lg p-0"
                  onClick={() => this.props.history.push(ROUTES.TICKET_MANAGEMENT)}
                  style={{
                    width: "100%",
                    height: "calc((100vh - 46px) / 3)",

                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd"
                  }}
                >
                  <img src={ticketManagerImage} style={{ width: "100px" }} />
                  <br />
                  {messages.ticketManagement}
                </a>
              </div>
              <div className="col col-6 text-center m-0 p-0">
                <a
                  className="btn btn-sq-lg p-0"
                  onClick={() => this.props.history.push(ROUTES.REPORT)}
                  style={{
                    width: "100%",
                    height: "calc((100vh - 46px) / 3)",
                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd"
                  }}
                >
                  <img src={reportImage} style={{ width: "100px" }} />
                  <br />
                  {messages.report}
                </a>
              </div>
              <div className="col col-6 text-center m-0 p-0">
                <a
                  className="btn btn-sq-lg p-0"
                  onClick={() => this.props.history.push(ROUTES.DRAW_RESULT)}
                  style={{
                    width: "100%",
                    height: "calc((100vh - 46px) / 3)",
                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd"
                  }}
                >
                  <img src={drawResultImage} style={{ width: "100px" }} />
                  <br />
                  {messages.drawResult}
                </a>
              </div>
              <div className="col col-6 text-center m-0 p-0">
                <a
                  onClick={() => this.props.history.push(ROUTES.RED_NUMBER)}
                  className="btn btn-sq-lg p-0"
                  style={{
                    width: "100%",
                    height: "calc((100vh - 46px) / 3)",
                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd"
                  }}
                >
                  <img src={redNumberImage} style={{ width: "100px" }} />
                  <br />
                  {messages.redNumber}
                </a>
              </div>
              <div className="col col-6 text-center m-0 p-0">
                <a
                  onClick={() => this.props.history.push(ROUTES.SETTINGS)}
                  className="btn btn-sq-lg p-0"
                  style={{
                    width: "100%",
                    height: "calc((100vh - 46px) / 3)",
                    borderRadius: "0px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd"
                  }}
                >
                  <img src={settingImage} style={{ width: "100px" }} />
                  <br />
                  {messages.setting}
                </a>
              </div>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

Dashboard.propTypes = {
  history: PropTypes.object
};

export default withLanguage(messages)(withRouter(Dashboard));
