import moment from "moment";

function genNextDays(nextDayNum) {
  let dateSelect = [];
  for (let index = 1; index <= nextDayNum; index++) {
    const nextDay = moment().day(index);
    const day = nextDay.format("DD");
    const month = nextDay.format("MM");
    const dayStr = nextDay.format("ddd");

    // const str = index.toString();
    // const pad = "00";
    // const ans = pad.substring(0, pad.length - str.length) + str;
    dateSelect = [
      ...dateSelect,
      {
        label: `${day}${month} (${dayStr})`,
        // value: index !== 1 ? `-${ans}` : ""
        value: `-${day}`
      }
    ];
  }
  return dateSelect;
}

export default genNextDays;
