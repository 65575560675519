import React, { Component } from "react";
import PropTypes from "prop-types";
import every from 'lodash/every';
import findIndex from 'lodash/findIndex';

class ModalSelectDate extends Component {
  state = {
    selections: []
  };

  onSelect = date => {
    if (this.state.selections.includes(date)) {
      this.setState({
        selections: this.state.selections.filter(_date => date !== _date)
      });
      return;
    }
    this.setState({ selections: [...this.state.selections, date] });
  };

  preProcess = () => {
    const { selections } = this.state;
    const { dateSelect } = this.props;
    if (selections.length === 1 && selections[0] === dateSelect[0].value) {
      return this.props.onSelect([''])
    } else if (selections.length === 2 || selections.length === 3) {
      const indexArr = selections.map((date) => findIndex(this.props.dateSelect, (_date) => _date.value === date));
      const condition = selections.length === 2 ? 1 : 2;
      const isContinuousisDay = every(indexArr, (index) => index <= condition)
      if (isContinuousisDay) {
        return this.props.onSelect([`-${selections.length === 2 ? 2 : 3}`])
      }
    }
    this.props.onSelect(selections);
  }

  render() {
    const { selections } = this.state;
    const { dateSelect, onSelect, onClose } = this.props;
    return (
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                D
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {dateSelect.map((select, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={selections.includes(select.value)}
                    onChange={event => this.onSelect(select.value)}
                  />
                  <label className="form-check-label">
                    {select.label}
                  </label>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => onSelect(["AUTO"])}
              >
                Auto
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.preProcess();
                  // onSelect(selections)
                }
                }
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ModalSelectDate.propTypes = {
  dateSelect: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalSelectDate;
