import React from 'react';
import { LanguageConsumer } from './LanguageContext';

const withLanguage = (messages) => (Component) => {
  class WithLanguage extends React.Component {
    render() {
      return (
        <LanguageConsumer>
          {({ lang, changeLanguage }) => {
            const _messages = messages[lang] || {};
            return <Component {...this.props} messages={_messages} changeLanguage={changeLanguage} lang={lang} />
          }}
        </LanguageConsumer>
      );
    }
  }
  return WithLanguage;
}

export default withLanguage;