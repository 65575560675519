import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Navbar from '../Navbar';
import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";
import * as LANGUAGES from '../../constants/languages';

const messages = {
  en: {
    title: 'En title',
  },
  cn: {
    title: 'Cn title',
  }
}

class Settings extends Component {
  static propTypes = {
    prop: PropTypes
  }

  goBack = () => {
    this.props.history.goBack();
  };

  changeLanguage = (event) => {
    this.props.changeLanguage(event.target.value);
  }

  render() {
    return (
      <div>
        <Navbar
          leftItems={[
            {
              label: 'Back',
              onClick: this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={this.props.messages.title}
          rightItems={[]}
        />
        <div className="p-3">
          <div className="form-check">
            <input className="form-check-input" type="radio" value={LANGUAGES.CN} onChange={this.changeLanguage} checked={this.props.lang === LANGUAGES.CN} />
            <label className="form-check-label">
              China
          </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="radio" value={LANGUAGES.EN} onChange={this.changeLanguage} checked={this.props.lang === LANGUAGES.EN} />
            <label className="form-check-label">
              English
          </label>
          </div>
        </div>
      </div>
    )
  }
}

export default withLanguage(messages)(withAuth(Settings));

