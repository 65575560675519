import React, { Component } from "react";
import PropTypes from "prop-types";

const AuthContext = React.createContext();

const initialState = {
  userInfo: {
    systemStatus: null,
    accountStatus: null,
    sessionId: null,
    level: null,
    playerList: null,
    drawDate: null,
    dateFormat: null,
    showBuy: null,
    showSetting: null,
    currency: null,
    line: null,
    showManager: null,
    showTransfer: null,
    orderFormat: null,
    showSms: null,
    inboxFormat: null,
    notice: null,
    password: null,
    pin: null,
  },
  authenticated: false
};

class AuthProvider extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = initialState;
  }

  componentWillMount() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.parseUserInfo(userInfo);
    }
  }

  parseUserInfo = userInfo => {
    const [
      systemStatus,
      accountStatus,
      sessionId,
      level,
      playerList,
      drawDate,
      dateFormat,
      showBuy,
      showSetting,
      currency,
      line,
      showManager,
      showTransfer,
      orderFormat,
      showSms,
      inboxFormat,
      notice,
      user,
      password,
      pin
    ] = userInfo.split(";");
    this.setState({
      userInfo: {
        systemStatus,
        accountStatus,
        sessionId,
        level,
        playerList,
        drawDate,
        dateFormat,
        showBuy,
        showSetting,
        currency,
        line,
        showManager,
        showTransfer,
        orderFormat,
        showSms,
        inboxFormat,
        notice,
        user,
        password,
        pin
      },
      authenticated: user && password
    });
  };

  login = userInfo => {
    localStorage.setItem("userInfo", userInfo);
    this.parseUserInfo(userInfo);
  };

  updateUserInfo = userInfo => {
    this.setState({ userInfo });
  };

  logout = () => {
    localStorage.removeItem("userInfo");
    this.setState(initialState);
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          userInfo: this.state.userInfo,
          authenticated: this.state.authenticated,
          login: this.login,
          logout: this.logout,
          updateUserInfo: this.updateUserInfo
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node
};

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
