import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { AuthConsumer } from "../App/AuthContext";

import logo from "../../images/logo.png";

import css from "./SignIn.module.scss";

const SignIn = () => (
  <AuthConsumer>
    {({ login, userInfo }) => (
      <LoginPanelForm login={login} userInfo={userInfo} />
    )}
  </AuthConsumer>
);

class SignInForm extends Component {
  state = {
    username: "",
    password: "",
    pin: '',
    message: ""
  };

  onChange = (prop, value) => {
    this.setState({ [prop]: value });
  };

  onSubmit = async event => {
    event.preventDefault();
    const { username, password, pin } = this.state;
    if (!username && !password) {
      return this.setState({ message: "Please enter username and password" });
    } else if (!username) {
      return this.setState({ message: "Please enter username" });
    } else if (!password) {
      return this.setState({ message: "Please enter password" });
    }

    try {
      const response = await fetch(
        `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/loginIP.jsp?user=${username}&pass=${password}`,
        {
          method: "GET",
          // mode: 'no-cors'
        }
      );
      const data = await response.text();
      const arr = data.split(";");
      const success = arr[0];
      if (success == 1) {

        this.props.login(`${data};${username};${password};${pin}`);
        // this.props.history.push(`${ROUTES.DASHBOARD}?res=${data}`);
        // console.log(window.location)
        window.open(
          `${window.location.protocol}//${window.location.host}/dashboard`,
          "mywindow",
          "menubar=1,resizable=1,width=480,height=778"
        );
        window.close();
      } else {
        this.setState({ message: "Login Fail" });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { username, password, pin, message } = this.state;
    return (
      <div className={css.SignIn}>
        <form className={css.Form} onSubmit={this.onSubmit}>
          <div className={css.LoginTitle}>
            <img src={logo} alt="Lottery" width="70" />
            <span>Lottery</span>
          </div>

          <input
            value={username}
            type="text"
            placeholder="Username"
            onChange={event => this.onChange("username", event.target.value)}
            autoFocus
          />
          <input
            value={password}
            type="password"
            placeholder="Password"
            onChange={event => this.onChange("password", event.target.value)}
          />
          <input
            value={pin}
            type="text"
            className={css.Pin}
            placeholder="Pin"
            onChange={event => this.onChange("pin", event.target.value)}
          />

          <span className={css.Msg}>{message}</span>

          <button type="submit" className={css.BtnLogin}>
            Login
          </button>
        </form>
      </div>
    );
  }
}
SignInForm.propTypes = {
  login: PropTypes.func,
  userInfo: PropTypes.string
};

const LoginPanelForm = withRouter(SignInForm);

export default withRouter(SignIn);
