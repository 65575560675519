import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthProvider } from "./AuthContext";
import { LanguageProvider } from "./LanguageContext";
import ProtectedRoute from "./ProtectedRoute";
import RouterRequireUnAuth from "./RouterRequireUnAuth";
import SignInPage from "../SignIn";
import DashboardPage from "../Dashboard";
import AddTicket from "../AddTicket";
import DrawResult from "../DrawResult";
import Report from "../Report";
import Settings from "../Settings";
import RedNumber from "../RedNumber";
import TicketManagement, { BrowseTicket, ShowAllTicket, FindByBetNumber } from "../TicketManagement";
import * as ROUTES from "../../constants/routes";

class App extends Component {
  render() {
    return (
      <Router>
        <LanguageProvider>
          <AuthProvider>
            <RouterRequireUnAuth
              path={ROUTES.SIGN_IN}
              component={SignInPage}
              exact
            />
            <ProtectedRoute path={ROUTES.DASHBOARD} component={DashboardPage} />
            <ProtectedRoute path={ROUTES.ADD_TICKET} component={AddTicket} />
            <ProtectedRoute path={ROUTES.TICKET_MANAGEMENT} component={TicketManagement} exact />
            <ProtectedRoute path={ROUTES.TICKET_MANAGEMENT_BROWSE_TICKET} component={BrowseTicket} />
            <ProtectedRoute path={ROUTES.TICKET_MANAGEMENT_SHOW_ALL_TICKET} component={ShowAllTicket} />
            <ProtectedRoute path={ROUTES.TICKET_MANAGEMENT_FIND_BY_BET_NUMBER} component={FindByBetNumber} />
            <ProtectedRoute path={ROUTES.SETTINGS} component={Settings} />
            <ProtectedRoute path={ROUTES.DRAW_RESULT} component={DrawResult} />
            <ProtectedRoute path={ROUTES.REPORT} component={Report} />
            <ProtectedRoute path={ROUTES.RED_NUMBER} component={RedNumber} />
          </AuthProvider>
        </LanguageProvider>
      </Router>
    );
  }
}

export default App;
