import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { setUserToLocalStorage, getUserFromLocalStorage } from '../../utils/dataManager';
import css from './Main.module.scss';
import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";

import Navbar from '../Navbar';
import getUserList from '../../utils/getUserList';
import * as ROUTES from "../../constants/routes";

const messages = {
  en: {
    title: 'Check/Void Ticket',
    browseTicket: 'Browse Order',
    showAllTicket: 'Show All Order',
    findByBetNumber: 'Search by bet number',
    back: 'Back',
  },
  cn: {
    title: '检查/废票-票单',
    browseTicket: '浏览票单',
    showAllTicket: '显示所有票单',
    findByBetNumber: '寻找-号码',
    back: '反回',
  }
}

class Main extends Component {
  static propTypes = {
    prop: PropTypes
  }

  state = {
    users: [],
    user: "",
  }

  componentDidMount() {
    const { playerList } = this.props.userInfo || {};
    const users = getUserList(playerList || "");
    this.setState({
      users,
      user: getUserFromLocalStorage() || users[0],
    });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  changeUser = event => {
    setUserToLocalStorage(event.target.value);
    this.setState({ user: event.target.value });
  };

  render() {
    const { users, user } = this.state;
    const { messages } = this.props;
    return (
      <div>
        <Navbar
          leftItems={[
            {
              label: messages.back,
              onClick: this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={messages.title}
        />

        <div className={css.Content}>
          <select
            className="form-control"
            onChange={this.changeUser}
            value={user}
          >
            {users.map((username, index) => (
              <option key={index}>{username}</option>
            ))}
          </select>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => this.props.history.push(ROUTES.TICKET_MANAGEMENT_BROWSE_TICKET)}
          >
            {messages.browseTicket}
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => this.props.history.push(ROUTES.TICKET_MANAGEMENT_SHOW_ALL_TICKET)}
          >
            {messages.showAllTicket}
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => this.props.history.push(ROUTES.TICKET_MANAGEMENT_FIND_BY_BET_NUMBER)}
          >
            {messages.findByBetNumber}
          </button>
          {/* <button type="button" class="btn btn-light">Show auto ticket</button> */}
        </div>
      </div>
    )
  }
}


export default withLanguage(messages)(withAuth(Main));

