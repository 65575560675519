import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import { getUserFromLocalStorage } from '../../utils/dataManager';
import getUserList from '../../utils/getUserList';
import withAuth from "../App/withAuth";
import withLanguage from "../App/withLanguage";
import Navbar from '../Navbar';
import css from './DrawResult.module.scss';
import { Alert, Loading } from "../UI";

const messages = {
  en: {
    title: 'Draw result',
    print: 'Print',
    show: 'Show',
    drawDate: 'Draw Date',
    serverReply: 'Server Reply',
    back: 'Back',
  },
  cn: {
    title: '成绩',
    print: '打印',
    show: '显示',
    drawDate: 'Draw Date',
    serverReply: 'Server Reply',
    back: '反回',
  }
}

class DrawResult extends Component {
  static propTypes = {
    prop: PropTypes
  }

  state = {
    loading: false,
    user: '',
    date: new Date(),
    result: '',
    showResult: false,
  }

  async componentDidMount() {
    const { playerList } = this.props.userInfo || {};
    const users = getUserList(playerList || "");
    const id = getUserFromLocalStorage();
    if (id || users[0]) {
      this.setState({ users, user: id || users[0] });
    }
  }

  changeDate = (date) => {
    this.setState({ date })
  }

  getBetHistory = async () => {
    if (this.state.user) {
      const { password, user: username, pin } = this.props.userInfo || {};
      this.setState({ loading: true });
      const newState = { ...this.state, loading: false };
      const drawDate = `${moment(this.state.date).format('YYYYMMDD')}`;
      try {
        const response = await fetch(
          `https://${pin ? `${pin}.` : ''}${process.env.REACT_APP_API_END_POIN}/resultIP.jsp?user=${username}&pass=${password}&drawType=&drawDate=${drawDate}`,
          {
            method: "GET",
          }
        );
        const data = await response.text();
        const [success, message, result] = (data || '').split(';');
        if (success == 1) {
          newState.result = result;
          newState.showResult = true;
        } else {
          newState.result = '';
          this.alert.open(message, true);
        }
      } catch (e) {
        console.log(e);
      }
      this.setState(newState);
    } else {
      this.alert.open('Please select a user!', true);
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  print = () => {
    window.print();
  }

  render() {
    const { loading, date, showResult, result } = this.state;
    const { messages } = this.props;
    return (
      <div className={css.BrowseTicket}>
        <Alert
          ref={instance => (this.alert = instance)}
          onVoidTicket={this.voidTicket}
        />
        {loading && <Loading />}
        <Navbar
          leftItems={[
            {
              label: messages.back,
              onClick: showResult ? () => this.setState({ showResult: false }) : this.goBack,
              icon: 'fa-chevron-left'
            }
          ]}
          title={showResult ? messages.serverReply : messages.title}
          rightItems={showResult ? [{
            label: messages.print,
            onClick: this.print,
          }] : []}
        />
        {showResult ? (<div id="result" className={css.Result}>
          {result}
        </div>) : (
            <React.Fragment>
              <div className={css.FilterBox}>
                <b>{messages.drawDate}</b>
                <DatePicker
                  selected={date}
                  onChange={this.changeDate}
                  dateFormat="yyyy/MM/dd"
                />

                <button
                  onClick={this.getBetHistory}
                  type="button"
                  className={`btn btn-light ${css.FindBtn}`}
                >
                  {messages.show}
                </button>
              </div>
            </React.Fragment>
          )}
      </div>
    )
  }
}

export default withLanguage(messages)(withAuth(DrawResult))
